import React from 'react';
import './Footer.css';
//import { Link } from 'react-router-dom';
//import { Link, animateScroll as scroll } from "react-scroll";
import { Link } from 'react-router-dom';


function Footer() {
    return (
        <>
            <div className='footer-container'>
                <ul className='footer-menu'>

                    <li className='footer-item'>

                        <Link to='/datenschutz' className='footer-links' >
                            Datenschutz
                            </Link>
                    </li>
                    <li className='footer-item'>
                        <Link
                            to='/impressum'
                            className='footer-links'
                        >
                            Impressum
                            </Link>
                    </li>
                </ul>
            </div>

        </>
    );
}

export default Footer;