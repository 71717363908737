import React, { Component, } from 'react';
import '../../App.css';
import { MapContainer, TileLayer, Marker, Popup, Rectangle, Polygon, Circle, Polyline} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import HeroSection from '../HeroSection';
import TextContainer from '../TextContainer';
import axios from 'axios';
import { Link } from 'react-router-dom';



const position = [51.505, -0.09]

const greenIcon = new L.icon({
    iconUrl: 'images/Marker_Icon.png',
    iconSize: [50, 50], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    //iconAnchor: [33, 60], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const Icon_bed = new L.icon({
    iconUrl: 'images/bed.png',
    iconSize: [40, 40], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    //iconAnchor: [33, 60], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const Icon_car = new L.icon({
    iconUrl: 'images/car.png',
    iconSize: [40, 40], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    //iconAnchor: [33, 60], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const Icon_party = new L.icon({
    iconUrl: 'images/party.png',
    iconSize: [40, 40], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    //iconAnchor: [33, 60], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const Icon_ring = new L.icon({
    iconUrl: 'images/ring.png',
    iconSize: [40, 40], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    //iconAnchor: [33, 60], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});


const multi_polygon = [
    [48.1769104, 9.973618],
    [48.1782857, 9.9742001],
  ]
const fillBlueOptions = { fillColor: 'blue' }
const blackOptions = { color: 'red' }

class Location extends Component {
    constructor(props) {
        super(props);
          this.state ={
             lat : 48.1760625,
             lng : 9.9742129,
            zoom : 16
          }
     }
    
    render() {
        const position = [this.state.lat,this.state.lng];
        return <div className="content">
            <div className="countdown-header">
                        {/*<h1 className="countdown-title">Julia & Patrick </h1 >*/}

                        <h3 className="countdown-subtitle">Adresse & Anfahrt</h3>
            </div>

            <TextContainer>
                        <p className='hochzeit_details'>TRAUUNG</p> {/* Farbe wie Header*/}
                        <p>Schwanenweiher Schlossgut Schwendi</p>
                        <p>Hauptstraße 35, 88477 Schwendi</p>
                    </TextContainer>
                    <TextContainer>
                <p>
                            Die Trauung findet bei guter Witterung draußen statt.
                </p>
            </TextContainer>

                    <TextContainer>
                        <p className='hochzeit_details'>FEIER</p>
                        <p>Schlosshofgalerie Schlossgut Schwendi</p>
                        <p>Hauptstraße 35, 88477 Schwendi</p>
                    </TextContainer>
                    <TextContainer>
                        <p className='hochzeit_details'>PARKEN</p>
                        <p>Parkmöglichkeiten findet Ihr entlang des Mühlewegs</p>
                        <br></br>
                    </TextContainer>
            <div className='Map'>

            
            <div className='Map-Container'>
        
        <MapContainer center={position} zoom={this.state.zoom} tap={false}>
            <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
            <Marker position={[48.1760625, 9.9742129]} icon={Icon_ring}> 
                <Popup>
                    Schwanenweiher - Trauung & Sektempfang
                 </Popup>
            </Marker>
            <Marker position={[48.1758329, 9.9753691]} icon={Icon_bed}> 
                <Popup>
                    Oberschwäbischer Hof - Übernachtung
                 </Popup>
            </Marker>
            <Marker position={[48.1775079, 9.9731983]} icon={Icon_car}> 
                <Popup>
                    Parken - Entlang Mühleweg
                 </Popup>
            </Marker>
            <Marker position={[48.1774325, 9.9744068    ]} icon={Icon_party}> 
                <Popup>
                    Schlosshofgalerie - Feier
                 </Popup>
            </Marker>

            
        </MapContainer>     
        </div>
        </div>
        </div>;
        }
}

export default Location;
