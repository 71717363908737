import React, { Component } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import Footer from './components/Footer'

import { BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom';
import Datenschutz from './components/pages/Datenschutz';
import Impressum from './components/pages/Impressum';
import { render } from '@testing-library/react';
import HeroSection from './components/HeroSection';
import Location from './components/pages/Location';
import Geschenke from './components/pages/Geschenke';
import CountdownPage from './components/pages/Countdown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextContainer from './components/TextContainer';



/*class App extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Home
          dark={false}
          id="home"
        />
        <CountdownPage deadline={"September, 24, 2022"} id="Countdown" />

        {/*
        <Kirche
          dark={true}
          id="kirche" />
        <Location
          dark={false}
          id="location" />
        <Geschenke
          dark={true}
        id="geschenke" />*

        <Footer />
      </>
    );
  }
}

export default App;*/

class App extends Component {

  render() {

    return (
      <>
        <HashRouter basename={'/'}>
          <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/home' exact component={Home} />
            <Route path='/impressum' component={Impressum} />
            <Route path='/datenschutz' component={Datenschutz} />
            <Route path='/location' component={Location} />

            <Route component={Datenschutz} />
          </Switch>
          <Footer />
        </HashRouter >
      </>
    );
  }
}

export default App;

//export default App;


