import { Hotel } from '@material-ui/icons';
import React, { Component } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import CountdownPage from './Countdown';
import Location from './Location';
import Hotels from './Hotels';
import Kontakte from './Kontakte';



function Home({ title, subtitle, dark, id }) {

  return (
    <>
      <div id={id}>
        <HeroSection
          title="Julia & Patrick"
          imgpath="images/Julia-Patrick-Querformat_1.jpg"
        />
        {/*<CountdownPage deadline={"September, 24, 2022, 00:15:00"} id="Countdown" />*/}
        <CountdownPage deadline={'24 SEP 2022 15:00:00'} id="Countdown"/>
        <Location/>
        <Hotels/>
        <Kontakte/>
      </div>
    </>
  );

}

export default Home;