import React, { Component, } from 'react';
import '../../App.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import HeroSection from '../HeroSection';
import TextContainer from '../TextContainer';
import axios from 'axios';
import { Link } from 'react-router-dom';


const position = [51.505, -0.09]

const greenIcon = new L.icon({
    iconUrl: 'images/Marker_Icon.png',
    iconSize: [50, 50], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [33, 60], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

class Hotels extends Component {
    constructor(props) {
        super(props);
          this.state ={
             lat : 48.20429518549093,
             lng : 9.884861203258374,
            zoom : 16
          }
     }
    
    render() {
        const position = [this.state.lat,this.state.lng];
        return <div className="content-header">
            
            <div className="countdown-header">
                        {/*<h1 className="countdown-title">Julia & Patrick </h1 >*/}

                        <h3 className="countdown-subtitle">Übernachtung</h3>
            </div>

            <TextContainer>
                <p>
                    Falls Ihr vor Ort übernachten möchtet,<br/> haben wir im Hotel Oberschwäbischen Hof Zimmer auf Patrick & Julia reserviert. <br/>
                </p>    
                <p>
                    Das Hotel befindet sich in Laufnähe zur Schlosshofgalerie.
                </p>
                <p>
                    <a href="https://www.oberschwaebischer-hof.de/" className='link'>
                    <div className="link-box">
                        <a href=""></a>Oberschwäbischer Hof
                    </div>
                    </a>
                </p>
                <p>&nbsp;</p>
            </TextContainer>
        


            
        </div>;
        }
}

export default Hotels;
