import React from 'react';
import '../../App.css';
import TextContainer from '../TextContainer';
import { Button, Card, Collapse, CardMedia, Typography, CardActions, CardContent, makeStyles, IconButton, CardHeader, Avatar } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from '@material-ui/core/colors';
import clsx from 'clsx';
import Image from 'material-ui-image';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

function Preloader(props) {
    return <img src="images/Geschenke.jpg" />;
}

function Geschenke({ title, subtitle, dark, id }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <>
            <div className={"content" + (dark ? "-dark" : "")} id={id}>

                <div>
                    <h1> Geschenke</h1>
                    <Card className="img-box">
                        <Image src="images/Geschenke.jpg" />
                    </Card>

                    <TextContainer>
                        <p>



                            Wir geben uns das JA-Wort und möchten gern mit Euch feiern.<br />

                        Die kirchliche Trauung findet am XX. Juli 2022 um 14 Uhr in der Pfarrkirche XYZ statt.<br />

                        Zur anschließenden Agape im Rosengarten und zur Hochzeitsfeier beim XYZ in XYZ laden wir herzlich ein.<br />


                        </p>
                    </TextContainer>

                </div>

            </div>
        </>
    );
}

export default Geschenke;


