import React, { Component } from 'react';
import './TextContainer.css';


class TextContainer extends Component {
    render() {
        return (
            <>
                <div className='text-container'>
                    {/*this.props.title != none}:return(<h1>Hallo</h1>);:return(<h2>Tschau</h2>);*/}
                    {this.props.title != null && <h1>          {this.props.title}</h1>}
                    {/*<h1>{this.props.title}</h1>*/}
                    <div className='text-container-content'>
                        {this.props.children}
                    </div>
                </div>

            </>
        );
    }
}

export default TextContainer;
