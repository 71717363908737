import React from 'react';
import './HeroSection.css';



function HeroSection(props, { title, subtitle, dark, id }) {
    return (
        <>
            <div id={id}>
                <div className='hero-container'>
                    <img src={props.imgpath} alt="Betz-Group" />
                    <h1>{props.title}</h1>

                    <p>{props.subtitle}</p>

                </div>
            </div>
        </>
    );
}

export default HeroSection;

