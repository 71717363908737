import { Card, CardMedia, Grid, makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React from 'react';
import '../../App.css';
import Image from 'material-ui-image'
import Countdown from 'react-countdown';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Component } from 'react';
import TextContainer from '../TextContainer';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));



//function CountdownPage({ title, subtitle, dark, id }) {
class CountdownPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: 0,
            hours: 15,
            minutes: 0,
            seconds: 0
        };
    }
    componentWillMount() {
        this.getTimeUntil(this.props.deadline);
    }
    componentDidMount() {
        setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
    }
    leading0(num) {
        return num < 10 ? "0" + num : num;
    }

    getTimeUntil(deadline) {
        const time = Date.parse(deadline) - Date.parse(new Date());
        if (time < 0) {
            this.setState({ days: 0, hours: 15, minutes: 0, seconds: 0 });
        } else {
            const seconds = Math.floor((time / 1000) % 60);
            const minutes = Math.floor((time / 1000 / 60) % 60);
            const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
            const days = Math.floor(time / (1000 * 60 * 60 * 24));
            this.setState({ days, hours, minutes, seconds });
        }
    }

    render() {
        return (
            <>
                <div className={"content" + (this.props.dark ? "-dark" : "")} id={this.props.id}>
                    <div className="countdown-header">
                        {/*<h1 className="countdown-title">Julia & Patrick </h1 >*/}

                        <h3 className="countdown-subtitle">Wir sagen JA!</h3>
                    </div>
                    <Grid
                        container
                        direction="row"
                        //justify="center"
                        alignItems="center"
                        className="countdown-container"
                        justify="space-evenly"
                    >
                        <div className="countdown-container-img">
                            <img src="images/SaveTheDate_other_color_and_text.png" className="countdown-img" />
                        </div>
                        <div className="countdown-container-box">
                            <div className="countdown-counter">
                                <span className="countdown-counter-number">{this.leading0(this.state.days)}</span>
                                <span className="countdown-counter-text">Tagen</span>
                            </div>

                            <div className="countdown-counter">
                                <span className="countdown-counter-number">{this.leading0(this.state.hours)}</span>
                                <span className="countdown-counter-text">Stunden</span>
                            </div>
                            <div className="countdown-counter">
                                <span className="countdown-counter-number">{this.leading0(this.state.minutes)}</span>
                                <span className="countdown-counter-text">Minuten</span>
                            </div>
                            <div className="countdown-counter">
                                <span className="countdown-counter-number">{this.leading0(this.state.seconds)}</span>
                                <span className="countdown-counter-text">Sekunden</span>
                            </div>
                        </div>

                    </Grid>

                    <TextContainer>
                        <p>
                            Zu unserer freien Trauung und unserem ganz besonderen Tag laden wir Euch herzlich ein!
                        </p>
                        <p>
                            Wir trauen uns um 15 Uhr am Schwanenweiher des Schlossguts in Schwendi. 
                        </p>
                        <p>
                            Anschließend feiern wir mit Euch bis in die Morgenstunden!
                        </p>
                        <p>&nbsp;</p>
                    </TextContainer>

                </div>

            </>
        );
    }
}

export default CountdownPage;


