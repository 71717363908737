import React, { Component, } from 'react';
import '../../App.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import HeroSection from '../HeroSection';
import TextContainer from '../TextContainer';
import axios from 'axios';
import { Link } from 'react-router-dom';


const position = [51.505, -0.09]

const greenIcon = new L.icon({
    iconUrl: 'images/Marker_Icon.png',
    iconSize: [50, 50], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [33, 60], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

class Hotels extends Component {
    constructor(props) {
        super(props);
          this.state ={
             lat : 48.20429518549093,
             lng : 9.884861203258374,
            zoom : 16
          }
     }
    
    render() {
        const position = [this.state.lat,this.state.lng];
        return <div className="content">
            
            <div className="countdown-header">
                        {/*<h1 className="countdown-title">Julia & Patrick </h1 >*/}

                        <h3 className="countdown-subtitle">Kontakte</h3>
            </div>

            <TextContainer>
                <p className='hochzeit_details'>BRAUTPAAR</p> {/* Farbe wie Header*/}
                <p>Julia & Patrick</p>
                <p>Riedweg 5</p>
                <p>88471 Baustetten</p>
                <br></br>
                <p>Julia: +49 174 6518606</p>
                <p>Patrick: +49 152 32711779</p>

            </TextContainer>

            <TextContainer>
                <p className='hochzeit_details'>TRAUZEUGEN</p> {/* Farbe wie Header*/}
                <p>Nicole Hildenbrand: +49 178 9062122</p>
                <p>Manuel Treder: +49 178 3471200</p>
                
            </TextContainer>
        


            
        </div>;
        }
}

export default Hotels;
